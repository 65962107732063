.musician-left {
  opacity: 0;
  position: absolute;
  top: -15%;
  margin-left: -38%;
  left: 0;
}
.developer-right {
  opacity: 0;
  position: absolute;
  top: 0;
  margin-right: -38%;
  right: 0;
  height: 100vh;
  width: 400px;
}
#main.musician #layer-1 .musician-left {
  animation: show 1s ease-in 2s 1 both;
}
#main.developer #layer-2 .developer-right {
  animation: show 1s ease-in 2s 1 both;
}
#main.musician .nav,
#main.developer .nav {
  animation: hide 1s ease-in 0.6s 1 both;
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
#main.musician #layer-1 {
  animation: slide-left 2s ease-in 1 both;
}
#main.developer #layer-2 {
  animation: slide-right 2s ease-in 1 both;
}
@keyframes slide-left {
  0% {
    margin-left: -30%;
  }
  100% {
    margin-left: 25%;
  }
}
@keyframes slide-right {
  0% {
    margin-left: 50%;
  }
  100% {
    margin-left: -10%;
  }
}
#main.musician #layer-2 {
  animation: slide-right-l2 2s ease-in 1 both;
}
#main.developer #layer-1 {
  animation: slide-left-l2 2s ease-in 1 both, hide 0.3s ease-in 1 1.7s both;
}
@keyframes slide-right-l2 {
  0% {
    margin-left: 50%;
  }
  100% {
    margin-left: 100%;
  }
}
@keyframes slide-left-l2 {
  0% {
    margin-left: -30%;
  }
  100% {
    margin-left: -80%;
  }
}
#main.musician #layer-3,
#main.musician #layer-6,
#main.musician #layer-5 {
  animation: slide-right-l3 2s ease-in 1 both;
}
#main.developer #layer-3,
#main.developer #layer-6,
#main.developer #layer-5 {
  animation: slide-left-l3 2s ease-in 1 both;
}
@keyframes slide-right-l3 {
  0% {
    margin-left: 0%;
  }
  100% {
    margin-left: 54%;
  }
}
@keyframes slide-left-l3 {
  0% {
    margin-left: 0%;
  }
  100% {
    margin-left: -53%;
  }
}
#main.musician #layer-4 {
  animation: slide-right-l4 2s ease-in 1 both;
}
#main.developer #layer-4 {
  animation: slide-left-l4 2s ease-in 1 both;
}
@keyframes slide-right-l4 {
  0% {
    margin-right: 0;
  }
  100% {
    margin-right: -55%;
  }
}
@keyframes slide-left-l4 {
  0% {
    margin-right: 0;
  }
  100% {
    margin-right: 50%;
  }
}
