@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  outline: none;
}
body {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  position: relative;
  z-index: -1;
  font-family: "Quicksand", sans-serif;
}
button {
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
}
.wrapper {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
}
#main::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='312' height='312' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23e3e3e3' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23e3e3e3'%3E%3Ccircle cx='769' cy='229' r='6'/%3E%3Ccircle cx='539' cy='269' r='6'/%3E%3Ccircle cx='603' cy='493' r='6'/%3E%3Ccircle cx='731' cy='737' r='6'/%3E%3Ccircle cx='520' cy='660' r='6'/%3E%3Ccircle cx='309' cy='538' r='6'/%3E%3Ccircle cx='295' cy='764' r='6'/%3E%3Ccircle cx='40' cy='599' r='6'/%3E%3Ccircle cx='102' cy='382' r='6'/%3E%3Ccircle cx='127' cy='80' r='6'/%3E%3Ccircle cx='370' cy='105' r='6'/%3E%3Ccircle cx='578' cy='42' r='6'/%3E%3Ccircle cx='237' cy='261' r='6'/%3E%3Ccircle cx='390' cy='382' r='6'/%3E%3C/g%3E%3C/svg%3E");
}
.container,
.wrapper,
.full {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: block;
}
.scene {
  min-height: 460px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  height: 100vh;
  width: 100vw;
}
.center {
  text-align: center;
}
.layer svg {
  transition: 1s;
}

li#layer-1 svg {
  height: 88vh;
  max-width: 70vw;
  margin-top: 10vh;
}
li#layer-2 svg {
  height: 98vh;
  max-width: 84vw;
}
li#layer-1,
li#layer-2 {
  height: 100vh;
}
#layer-1 {
  transform-origin: left bottom;
  margin-left: -30%;
  animation: show 2s ease-in-out 1s 1 both;
}
#layer-2 {
  transform-origin: right bottom;
  margin-left: 50%;
  animation: show 2s ease-in-out 1s 1 both;
}
#layer-3 {
  text-align: center;
  left: 25% !important;
  transform-origin: bottom;
  transform: scale(0.85) translate(-40%) !important;
  height: 100vh;
  text-align: center;
}
.diego-wrapper {
  position: relative;
  height: 100vh;
}
#main.home #layer-1,
#main.home #layer-2 {
  opacity: 1 !important;
}
#layer-3 img {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  animation: scale-show 1.5s ease 1;
  transform-origin: bottom;
  max-width: 50vw;
}
.dialog {
  background: url(../assets/bg-dialog.png) no-repeat center center;
  background-size: 70%;
  animation: scale-show 2s ease 1s 1 forwards,
    shake 6s ease 4s infinite forwards;
  transform-origin: bottom;
  transition: 0.5s;
  opacity: 0;
}
.dialog svg {
  transform: scale(0.8);
}
@keyframes scale-show {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes shake {
  0% {
    transform: scale(1) translate(0, 0);
  }
  10% {
    transform: scale(0.92) translate(-15px, -5px) rotate(2deg);
  }
  20% {
    transform: scale(0.96) translate(0, 0) rotate(0deg);
  }
  30% {
    transform: scale(0.93) translate(-10px, 10px) rotate(3deg);
  }
  40% {
    transform: scale(1) translate(0px, -0px) rotate(0deg);
  }
  50% {
    transform: scale(0.95) translate(5px, -5px) rotate(2deg);
  }
  70% {
    transform: scale(0.86) translate(10px, -10px) rotate(0deg);
  }
  90% {
    transform: scale(0.93) translate(12px, 0) rotate(3deg);
  }
  100% {
    transform: scale(1) translate(0, 0) rotate(0deg);
  }
}

.profile-button {
  position: absolute;
  z-index: 999;
  bottom: 105px;
  left: 80px;
  background-color: transparent;
  width: 78px;
  max-width: 20vw;
  height: 71px;
  text-align: center;
  opacity: 1;
  animation: show-profile-button 1.7s ease-in-out 1 forwards;
}
@keyframes show-profile-button {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.profile-button a {
  position: absolute;
  top: 29px;
  left: 23px;
  color: #fff8cb;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  height: 80%;
}
.profile-button a:hover {
  animation: scale-increase 0.4s ease-out 1 alternate both;
  color: #fff;
  transition: 0.7s;
}
.profile-button::before {
  content: "";
  width: 115%;
  height: 10px;
  background-color: #000;
  display: block;
  position: absolute;
  transform: rotate(-26deg);
  bottom: -15px;
  left: 32px;
  border-radius: 50%;
  filter: blur(4px);
}
@keyframes scale-increase {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(20deg);
  }
}
#layer-4 {
  right: 22%;
  left: auto !important;
  top: auto !important;
  bottom: 10%;
  transform: translate3d(0px, 0px, 0px) !important;
}
.rotate-6 {
  transform: rotate(6deg);
}

#layer-5 {
  top: 2% !important;
  left: 35% !important;
  text-align: center;
}
.logo {
  width: 255px;
  margin: 0 auto;
  position: relative;
  opacity: 0;
  animation: scale-show 2s ease 1s 1 forwards,
    shake 8s ease 2s infinite forwards;
}

.black-logo {
  animation: moving-size-rotate 6s ease 3s infinite forwards;
  transition: 0.4s;
}
.cloud {
  animation: moving-size 6s ease 3s infinite forwards;
  transition: 0.4s;
}
@keyframes moving-size {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(0.91);
  }
  40% {
    transform: scale(1.1);
  }
  60% {
    transform: scale(0.95);
  }
  80% {
    transform: scale(0.92);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes moving-size-rotate {
  0% {
    transform: scale(1) rotate(6deg);
  }
  20% {
    transform: scale(0.8) rotate(7deg);
  }
  40% {
    transform: scale(1) rotate(6deg);
  }
  60% {
    transform: scale(0.7) rotate(9deg);
  }
  80% {
    transform: scale(0.92) rotate(5deg);
  }
  100% {
    transform: scale(1) rotate(6deg);
  }
}
.black-logo {
  -webkit-filter: grayscale(100%) brightness(0%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%) brightness(0%);
}
.black-logo:hover,
.black-img:hover {
  transition: 0.6s !important;
  -webkit-filter: grayscale(0%) brightness(100%) !important; /* Safari 6.0 - 9.0 */
  filter: grayscale(0%) brightness(100%) !important;
}
.logo img.black-logo {
  position: absolute;
  left: 52px;
  top: 24px;
  cursor: pointer;
  z-index: 999;
}
.logo .circles img {
  display: block;
  text-align: center;
  margin: 0 auto;
}
.logo .circles img:nth-child(1) {
  animation: shake 8s ease 3s infinite forwards;
}
.logo .circles img:nth-child(2) {
  animation: shake 11s ease 3s infinite forwards;
}
.logo .circles img:nth-child(3) {
  animation: shake 12s ease 3s infinite forwards;
}
a.btn-sound:hover::after {
  content: "Listen Filelife";
  display: inline-block;
  text-transform: uppercase;
  color: #444;
  font-size: 10px;
  transition: 1s;
  margin-left: 0.25rem;
  line-height: 1rem;
  vertical-align: top;
}
.dialog .dialog-body {
  position: absolute;
  width: 53%;
  bottom: 125px;
  right: 95px;
  z-index: 9;
}
.dialog .dialog-body .carousel {
  display: none;
}
.dialog .dialog-body p {
  font-size: 11px;
  color: #555;
  font-weight: bold;
}
.dialog .dialog-body img {
  vertical-align: middle;
  margin-right: 0.5rem;
}
.lnk-profile {
  text-transform: uppercase;
  color: #444;
  font-size: 10px;
  text-align: right;
  display: block;
  margin-right: 4rem;
  font-weight: bold;
}
.lnk-profile:hover {
  color: #000;
  font-size: 13px;
  transition: 1s;
}
.change-theme {
  position: absolute;
  z-index: 999;
  top: 10px;
  left: 5px;
  cursor: pointer;
}
.change-theme input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}
.change-theme label {
  cursor: pointer;
  text-indent: -9999px;
  width: 32px;
  height: 17px;
  background: grey;
  float: right;
  border-radius: 100px;
  position: relative;
}

.change-theme label:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.change-theme input:checked + label {
  background: #0077ff;
}

.change-theme input:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.change-theme label:active:after {
  width: 45px;
}
.btn-sound {
  position: relative;
  z-index: 99;
  animation: show 2s ease 2s 1 both;
}
.sprite {
  display: block;
  background-image: url(../assets/sprites.png);
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.nav-musician {
  width: 106px;
  height: 63px;
  background-position: -68px -127px;
  position: absolute;
  left: 10%;
}
.nav-developer {
  width: 150px;
  height: 60px;
  background-position: -13px -197px;
  position: absolute;
  right: 10%;
}
.nav-musician {
  animation: show 2s ease 2s 1 both, nav-move 5s ease 2s infinite forwards;
}
.nav-developer {
  animation: show 2s ease 2s 1 both, nav-move 4s ease 3s infinite forwards;
}
@keyframes nav-move {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  60% {
    transform: translate(0, 0) rotate(0deg);
  }
  70% {
    transform: translate(-15px, 0) rotate(2deg);
  }
  80% {
    transform: translate(0, 0) rotate(0deg);
  }
  90% {
    transform: translate(-10px, 0) rotate(3deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}
.sprite:hover {
  transition: 0.3s;
  opacity: 0.7;
}
#layer-6 {
  margin-top: 3%;
}
.nav {
  width: 100vw;
}
.social-media {
  text-align: left;
  position: absolute;
  right: 25vw;
  margin-top: 14%;
  animation: show 2s ease 2s 1 both;
}
.social-media-dialog {
  position: relative;
  top: 35px;
  left: 15px;
}
.social-media a,
.social-media-dialog a {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 3px;
}
a.btn-email {
  background-position: -11px -29px;
}
a.btn-linkedin {
  background-position: -41px -29px;
}
a.btn-twitter {
  background-position: -69px -27px;
}
a.btn-facebook {
  background-position: -97px -27px;
}
a.btn-youtube {
  background-position: -127px -27px;
}
.block {
  display: block;
}
.mb-2 {
  margin-bottom: 0.3rem;
}
.social-media span label {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
}
.social-media span i {
  width: 16px;
  height: 8px;
  display: inline-block;
  background-position: -14px -13px;
  margin-right: 5px;
}
.video {
  width: 400px;
  position: relative;
  animation: balance 10s ease infinite both;
}
.bg-video {
  position: absolute;
  top: 11%;
  right: 12%;
  width: 161px;
  height: 208px;
  background: url(../assets/musician-elements.png) no-repeat 80% 15%;
}
@keyframes balance {
  0% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-2deg);
  }
  80% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.iframe {
  position: absolute;
  bottom: 3vh;
  width: 100%;
  left: 80px;
  right: 0;
  transform: rotate(1deg);
}
.musician-bottom {
  background: url(../assets/musician-bottom.png) no-repeat top left;
  width: 353px;
  height: 142px;
  position: absolute;
  bottom: -17%;
  left: 11%;
}

.back-home {
  width: 106px;
  height: 63px;
  position: absolute;
  z-index: 99;
}
.musician-left .back-home {
  left: 115%;
  top: 20%;
}
.developer-right .back-home {
  left: -50%;
  top: 15%;
}
.back-home button {
  width: 106px;
  height: 63px;
}
.musician-left .back-home button {
  background-position: -80px -56px;
}
.developer-right .back-home button {
  background-position: 20px -63px;
}
#main.developer #layer-2 svg {
  max-width: 75vw;
}
.rocket {
  background: url(../assets/rocket.svg) no-repeat top left;
  height: 110px;
  width: 220px;
  position: absolute;
  bottom: 15%;
  right: 15%;
  opacity: 1;
  animation: rocket 5s ease-in-out infinite 2s alternate backwards;
}
body.safari .rocket,
body.safari .rocket::before,
body.ios .rocket,
body.ios .rocket::before {
  animation: none !important;
}

@keyframes rocket {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  30% {
    transform: translate(0, 0) rotate(0deg);
  }
  35% {
    transform: translate(0, 0) rotate(3deg);
  }
  40% {
    transform: translate(0, 0) rotate(-3deg);
  }
  45% {
    transform: translate(0, 0) rotate(3deg);
  }
  50% {
    transform: translate(0, 0) rotate(0deg);
  }
  70% {
    transform: translate(-3px, 15px) rotate(0deg);
  }
  80% {
    transform: translate(0, 0) rotate(0deg);
  }
  90% {
    transform: translate(-3px, 15px) rotate(0deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}
.rocket::before {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  background-color: #feb61a;
  background-image: radial-gradient(circle, #feb61a, #a93120);
  border-radius: 50%;
  position: absolute;
  bottom: 9px;
  left: 96px;
  filter: blur(10px);
  animation: show 0.5s ease-in-out alternate infinite both;
}
.rocket h2 {
  position: absolute;
  top: -15px;
  right: 0;
  left: 85px;
  text-transform: uppercase;
  font-size: 14px;
}
.rocket h2::before {
  content: "Do You";
  display: block;
  font-size: 30px;
  transform: translate(-57px, -30px) rotate(-11deg);
  position: absolute;
}

.rocket h3 {
  text-transform: uppercase;
  color: #cc0006;
  font-size: 13px;
  text-align: center;
  transform: translate(0, 28px) rotate(2deg);
}
.rocket h3 a {
  color: #cc0006;
}

.aulas {
  width: 208px;
  height: 152px;
  position: absolute;
  bottom: 0;
  right: 85%;
}
.aulas a {
  display: block;
  width: 50%;
  position: absolute;
  top: 68px;
  left: 82px;
  z-index: 2;
}
.aulas h2 {
  font-size: 21px;
  text-transform: uppercase;
  font-weight: normal;
  text-align: center;
}
.aulas p {
  font-size: 11px;
}
.aulas svg {
  max-width: none !important;
  height: 100% !important;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  bottom: 0;
}
.jobs {
  width: 380px;
  height: 380px;
  position: relative;
}
.jobs h2 {
  position: absolute;
  text-transform: uppercase;
  font-size: 16px;
  top: 56px;
  left: 85px;
  z-index: 3;
}
.jobs svg {
  position: absolute;
  width: 100%;
  max-width: none !important;
  height: 100% !important;
  z-index: 2;
}
.jobs .card {
  position: absolute;
  top: 94px;
  left: 13px;
}
.jobs .card img {
  display: none;
}
.jobs .card-body {
  position: absolute;
  z-index: 4;
  bottom: 35px;
  right: 35px;
  display: none;
}
.jobs .card-body h4 {
  color: #fdae28;
  font-size: 11px;
  text-transform: uppercase;
}
.jobs .card-body h3 {
  font-size: 12px;
  text-transform: uppercase;
}
.jobs .card-body a {
  font-size: 10px;
  text-transform: uppercase;
  color: #bbb;
}
.jobs .card-body a:hover {
  color: #fff;
  transition: 1s;
}

.jobs .nav-job svg {
  width: auto !important;
  position: static !important;
}
.jobs .nav-job {
  width: 57px;
  height: 60px;
  border: none;
  position: absolute;
  top: 150px;
  z-index: 9;
}
.jobs .nav-job.next {
  right: 35px;
}
.jobs .nav-job.prev {
  left: -60px;
}
.stack {
  width: 10vw;
  height: 15vh;
  position: absolute;
  bottom: 20%;
  right: 16%;
  transform: rotate(11deg) scale(0.6);
}

.stack img {
  max-height: 120px;
  max-width: 150px;
  display: none;
}
.black-img {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  margin-right: 5px;
}
.github {
  position: absolute;
  z-index: 9;
  bottom: 3%;
  right: 16%;
}
.github h2 {
  text-transform: uppercase;
  font-size: 16px;
}
.github h2 span {
  font-size: 11px;
  display: block;
}
.github a {
  color: #000;
}
.github strong {
  float: right;
}
.github .black-img {
  height: auto;
  float: left;
}
.uppercase {
  text-transform: uppercase;
}
.f-80 {
  font-size: 80%;
}
.f-70 {
  font-size: 70%;
}
.bold {
  font-weight: bold;
}

.tv {
  position: absolute;
  top: 25vh;
  left: 11.7vw;
  animation: noise-tv 2s ease-in-out 3s infinite both;
}
@keyframes noise-tv {
  0% {
    filter: invert(100%) saturate(100%);
  }
  20% {
    filter: saturate(100%);
  }
  50% {
    filter: invert(100%);
  }
  70% {
    filter: brightness(100%);
  }
  90% {
    filter: invert(100%) saturate(100%);
  }
  100% {
    filter: brightness(100%);
  }
}
