@media (max-width: 1300px) {
  .tv {
    display: none;
  }
  .logo {
    transform: scale(0.7) !important;
    top: -35px;
  }
  #layer-4 {
    right: 5%;
    transform: scale(0.65) !important;
  }
  .profile-button {
    transform: scale(0.65);
    left: 48px;
    bottom: 200px;
  }
  #layer-1 svg,
  #layer-2 svg {
    height: 100vh;
    max-width: 80vw;
  }
  .stack {
    display: none;
  }
}
@media (min-width: 500px) and (max-width: 1000px) and (orientation: landscape) {
  .diego-wrapper {
    margin-top: 26vh;
  }
}
@media (min-width: 500px) and (max-width: 1000px) {
  .profile-button {
    display: none;
  }
  #main.developer #layer-2 svg {
    max-width: 100vw;
  }
  #main.developer #layer-2 {
    animation: slide-right-mobile-l2 2s ease-in 1 both;
  }
  @keyframes slide-right-mobile-l2 {
    0% {
      margin-left: 50%;
    }
    100% {
      margin-left: -47%;
    }
  }
  .social-media {
    margin-top: 4%;
  }
  .nav-developer {
    right: 6%;
    top: 10px;
  }
  .iframe {
    bottom: 4vh;
    width: 177px;
    left: 143px;
    height: 133px;
  }
  .iframe iframe {
    width: 100%;
    height: 100%;
  }
  .bg-video {
    right: 45%;
  }
  .musician-left .back-home {
    top: 15%;
  }
  #layer-4 {
    right: 12%;
  }
  #main.developer .logo {
    display: none;
  }
  .jobs {
    transform: scale(0.7);
    top: -60px;
    left: -25px;
  }
  .aulas {
    right: 73%;
  }
  .rocket {
    bottom: 5%;
    right: 21%;
    transform: scale(0.7) !important;
  }
}
@media (max-width: 500px) {
  body {
    overflow: visible !important;
  }
  .logo-wrapper {
    transform: scale(0.8);
  }
  .diego-wrapper {
    margin-top: 0;
  }
  .profile-button {
    transform: scale(0.65);
    left: 48px;
    bottom: 200px;
  }
  #layer-5 {
    top: 19% !important;
    left: 2% !important;
  }
  .nav-developer,
  .nav-musician {
    top: 60px !important;
  }
  .social-media {
    right: 5%;
    margin-top: 51%;
  }
  #main.developer .social-media {
    right: 13%;
    margin-top: 71%;
  }
  #layer-4 {
    right: -33% !important;
    bottom: -3%;
  }
  #layer-3 {
    top: 100px !important;
    left: 3% !important;
  }
  #layer-3 img {
    max-width: 100vw;
  }
  #layer-1 {
    margin-left: auto;
  }
  #layer-1 svg {
    height: 100% !important;
    max-width: 100vw !important;
    width: 100%;
  }
  #layer-2 {
    width: 100%;
    margin-left: auto;
  }
  li#layer-2 svg {
    height: 100vh;
    max-width: 100vw !important;
  }
  .developer-right {
    margin-right: -10%;
  }
  .bg-video {
    right: 20%;
  }
  .aulas {
    right: 43%;
  }
  #main.developer .dialog,
  #main.developer .logo,
  #main:not(.musician) .svgMusician {
    display: none;
  }
  .developer-right .back-home {
    left: 19%;
    top: 0%;
  }
  .developer-right .jobs {
    transform: scale(0.8) translate(-37px, 20px);
  }
  svg.svgDeveloper {
    transform: none !important;
  }
  #main.developer #layer-2 {
    animation: none !important;
  }
  #main.developer svg.svgDeveloper {
    margin-top: 30px;
  }
  .iframe {
    bottom: 3.5vh;
    width: 355px;
    left: 20px;
    height: 260px;
  }
  .iframe iframe {
    width: 100%;
    height: 100%;
  }
  .musician-bottom {
    bottom: -24%;
    left: 4%;
  }
  .musician-left .back-home {
    left: 10%;
    top: 28%;
  }
  #layer-6,
  #main.home #layer-1,
  #main.home #layer-2,
  #main.home #layer-5 {
    transform: none !important;
  }
}
@media (max-width: 390px) {
  .profile-button {
    left: 25px;
    bottom: 157px;
  }
  #layer-4 {
    right: -41% !important;
    bottom: -7%;
  }
  .svgDeveloper {
    margin-top: 60px;
  }
  #main.developer .social-media,
  .rocket {
    display: none !important;
  }
  .iframe {
    bottom: 4.33vh;
    width: 331px;
    left: 22px;
    height: 245px;
  }
}

@media (max-width: 800px) and (min-height: 1000px) {
  #layer-3 {
    left: 15% !important;
  }
  .diego-wrapper {
    margin-top: 0;
  }
  #layer-3 img {
    max-width: 80vw;
  }
  #layer-4 {
    right: 0%;
    bottom: 15%;
  }
  #layer-5 {
    top: 21% !important;
    left: 28% !important;
  }
  .logo {
    transform: none !important;
    top: 0 !important;
  }
  .social-media {
    margin-top: 15%;
    right: 5vw;
  }
  #main.developer .social-media {
    margin-top: 15%;
    right: 25vw;
  }
  .developer-right .back-home {
    right: 10%;
    top: 33%;
  }
  .jobs {
    transform: none !important;
    top: 0 !important;
  }
  .rocket {
    transform: none !important;
    bottom: 15% !important;
  }
  .iframe {
    bottom: 6.2vh;
    width: 418px;
    left: 20px;
    height: 310px;
  }
}

@media (min-width: 1400px) {
  .social-media {
    right: 35%;
    margin-top: 6%;
  }
}
@media (min-width: 1000px) {
  .nav-developer {
    right: 30%;
  }
}
@media (min-width: 1025px) {
  #layer-3 img {
    top: 25vh;
  }
}
@media (min-width: 1900px) {
  #layer-3 img {
    max-width: 100vw !important;
    width: 50vw;
    top: auto !important;
  }
  .tv {
    top: 29vh;
    left: 11.2vw;
    height: 210px;
  }
  .iframe {
    bottom: 4.7vw;
    width: 21.6vmax;
    left: 23px;
    height: 15.8vmax;
  }
  .iframe iframe {
    width: 100%;
    height: 100%;
  }
  .video svg {
    margin-top: 3vh !important;
  }
  .profile-button {
    bottom: 29vh;
    left: 8vw;
  }
  #layer-4 {
    right: 29%;
    bottom: 28%;
  }
  .stack {
    bottom: 24%;
  }
}
@media (min-width: 1900px) and (min-height: 1200px) {
  .tv {
    top: 31.3vh;
  }
  .iframe {
    bottom: 7.5vw;
    width: 21.6vmax;
    left: 23px;
  }
}
@media (min-width: 2560px) and (height: 1080px) {
  #layer-3 img {
    width: 40vw;
  }
  #layer-4 {
    right: 38%;
    bottom: 34%;
  }
  .tv {
    top: 25.3vh;
    height: 22vh;
    left: 14.8vw;
  }
  .iframe {
    bottom: 3.5vw;
    width: 16vmax;
    height: 12vmax;
    left: 24px;
  }
  .stack {
    bottom: 17%;
  }
}
@media (min-width: 2560px) and (height: 1200px) {
  #layer-3 img {
    width: 45vw;
  }
  .tv {
    top: 25.3vh;
    height: 22vh;
    left: 12.5vw;
  }
  .iframe {
    bottom: 5.5vw;
    width: 16vmax;
    height: 12vmax;
    left: 24px;
  }
  .stack {
    bottom: 17%;
  }
}
@media (min-width: 2560px) and (min-height: 1440px) {
  .tv {
    top: 28.3vh;
    height: 20vh;
  }
  .iframe {
    width: 16vmax;
    height: 11.8vmax;
    bottom: 9.8vw;
    left: 24px;
  }
  .musician-left {
    margin-left: -30%;
  }
}
@media (min-width: 3840px) and (min-height: 2160px) {
  .stack img {
    max-height: 400px;
    max-width: 400px;
    min-width: 300px;
  }
  #layer-4 {
    right: 40%;
    bottom: 35%;
  }
  .developer-right .jobs {
    transform: scale(1.3);
    top: 60px;
  }
  .iframe {
    width: 10.7vmax;
    height: 7.8vmax;
    bottom: 14.8vw;
    left: 24px;
  }
}
